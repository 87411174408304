<template>
  <v-container fluid>
    <Header headerClass="valutare-aria" title="Valutare l'aria" />
    <v-container page class="sistema-regionale">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Sistema regionale</h2>
          <div class="w-80">
            <p class="mt-40">
              La valutazione della qualità dell'<strong>aria ambiente</strong> è
              necessaria per individuare, su tutto il territorio regionale, le
              aree in cui si superano i valori previsti dalla normativa.<br />
              Tale valutazione è necessaria per pianificare le politiche di
              intervento su tali aree, al fine di rientrare entro i limiti di
              legge nel minor tempo possibile.
            </p>
            <p class="mt-40">
              Nel corso degli anni, in Piemonte è stata messa a punto una serie
              di
              <strong>strumenti</strong> per verificare la qualità dell’aria.<br />
              Il Sistema Regionale Integrato della Qualità dell’Aria messo a
              punto in regione Piemonte si compone di un complesso
              <!-- eslint-disable -->
              <a
                href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/modellistica/modellistica"
                target="_blank"
              >Sistema Modellistico</a>
              che integra i dati provenienti dal
              <a
                href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/sistema-regionale-rilevamento-della-qualita-dellaria-srqa"
                target="_blank"
              >Sistema Regionale di Rilevamento della Qualità dell’Aria (SRRQA)</a>
              e dall'
              <a
                href="http://www.sistemapiemonte.it/cms/privati/ambiente-e-energia/servizi/474-irea-inventario-regionale-delle-emissioni-in-atmosfera"
                target="_blank"
              >Inventario Regionale delle Emissioni in Atmosfera (IREA),</a>
              con l’obiettivo di stimare, su base annuale, i livelli di
              inquinamento sul territorio regionale, compresi i Comuni sprovvisti
              di centraline.
            </p>
            <p class="mt-40 caption">
              NOTA: “aria ambiente” significa che le misure devono avvenire
              all’aperto, dove vivono le persone e fuori dai luoghi di lavoro
            </p>

            <v-card class="c-left" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <h3>
                    Concentrazioni o emissioni?
                  </h3>
                  <p class="mt-40">
                    Per  definire il contributo delle diverse tipologie di fonti
                    di inquinamento alle concentrazioni in aria misurate nelle
                    stazioni di monitoraggio non è sufficiente conoscere le
                    quantità <strong>emesse</strong> dalle stesse fonti;
                    occorre - come previsto esplicitamente dalla normativa - 
                    utilizzare sistemi modellistici che simulino i processi
                    di dispersione, trasporto e trasformazione chimica a cui
                    sono soggetti gli  inquinanti una volta immessi in atmosfera. 
                  </p>
                  <p class="mt-40">
                    Questa metodologia (detta di "source apportionment" vale a
                    dire "ripartizione del contributo delle sorgenti") è stata
                    utilizzata all’interno del Piano regionale di Qualità
                    dell’Aria per calcolare in tutte le stazioni di monitoraggio
                    regionali quali sono i contributi  del traffico veicolare,
                    del riscaldamento civile, dell’industria, del  settore
                    agricolo, ecc. alle <strong>concentrazioni</strong> in aria
                    misurate.
                  </p>
                  <div class="inquinanti-fonti mt-40">
                    <ul>
                      <li>
                        <a
                        href="https://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-le-fonti"
                        target="_blank"
                        >
                          ARPA - PM<small>10</small>: le sorgenti
                          <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="http://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-il-riscaldamento-domestico"
                          title="Questo link si apre in una nuova scheda"
                          >ARPA - PM<small>10</small>: il riscaldamento domestico
                          <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-il-trasporto-su-strada"
                          title="Questo link si apre in una nuova scheda"
                          >ARPA - PM<small>10</small>: il trasporto su strada
                          <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuValutareAria page="sistema-regionale" />
        </v-col>
      </v-row>
    </v-container>
    <v-container hexagon></v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuValutareAria from "@/views/valutare-aria/MenuValutareAria";

export default {
  components: {
    Header,
    MenuValutareAria
  }
};
</script>
